import React from "react";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const AnswerItem = (props) => {
  return (
    <button
      className={`question-option ${
        props.selected ? "selected" : "bg-light"
      } d-flex flex-row border px-2 py-1 align-items-center rounded-sm cursor-pointer exam-answer`}
      aria-hidden="true"
    >
      {/* numeration */}
      <div
        className="bg-primary text-light px-2 py-1 rounded-sm"
        style={{ fontSize: "24px" }}
      >
        {props.number}
      </div>
      {/* status */}
      {props.selected ? (
        <CheckCircleOutlineRoundedIcon
          style={{ fontSize: "30px" }}
          className="text-primary ml-1"
        />
      ) : (
        <RadioButtonUncheckedRoundedIcon
          style={{ fontSize: "30px" }}
          className="text-primary ml-1"
        />
      )}

      <span className="ml-2">{props.title}</span>
    </button>
  );
};

export default AnswerItem;
